import React, { useEffect, useState } from "react";
import BannerSection from "../Components/Home/BannerSection";
import GridSection from "../Components/Home/GridSection";
import TestimonialsandBlogSec from "../Components/Home/TestimonialsandBlogSec";
import ClientLogoCarousel from "../Components/Home/ClientLogoCarousel";
import Footer from "../Components/Layout/Footer";
import { Link, useLocation } from "react-router-dom";
import Header from "../Components/Layout/Header";
import BlogSec from "../Components/Home/BlogSec";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../Components/Common/HelmetData";



const Home = () => {

  const [showObituary, setShowObituary] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    document.title = "Think Quotient";
    AOS.init();

    // Check localStorage for the obituary display flag
    // const hasShownObituary = localStorage.getItem("hasShownObituary");

    // if (!hasShownObituary) {
    //   setShowObituary(true);
    //   localStorage.setItem("hasShownObituary", "true"); // Set flag to true after showing
    // }

    // setTimeout(() => {
    //   setFadeIn(true);
    // }, 200);


    // const timer = setTimeout(() => {
    //   setFadeOut(true);
    //   setTimeout(() => {
    //     setShowObituary(false);
    //   }, 1000); // Hide the obituary 1 second after fading out
    // }, 7000); // Automatically close after 7 seconds

    // const closeObituary = () => {
    //   setFadeOut(true);
    //   setTimeout(() => setShowObituary(false), 1000);
    // };

    // window.addEventListener("scroll", closeObituary);
    // window.addEventListener("click", closeObituary);

    //   return () => {
    //     window.removeEventListener("scroll", closeObituary);
    //     window.removeEventListener("click", closeObituary);
    //     clearTimeout(timer);
    //   };
  }, []);

  const location = useLocation();

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  const [showModal, setShowModal] = useState(false);

  // Show modal on page load
  useEffect(() => {
   
  
    setShowModal(true); // Render the modal first

    setTimeout(() => {
      setFadeIn(true); // Trigger fade-in effect after rendering
    }, 300);
  
  }, []);
  
  const closeModal = () => {
    setFadeOut(true);
    setTimeout(() => setShowModal(false), 1000);
  };

  const pageTitle = "Expert in Project OutSourcing and Technology Consulting";
  const metaTitle = "INDIA's TOP IT TRAINING COURSE.. PAY ONLY AFTER YOU ARE PLACED";
  const helmetContent = getHelmetContent();
  return (

    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []; 
          function gtag() { 
            dataLayer.push(arguments); 
          } 
          gtag("js", new Date()); 
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { 
            dataLayer.push(arguments); 
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>

      </Helmet>

      <div className="overflow-hidden">
        {/* Conditionally display the obituary image */}
        {/* {showObituary && (
          <div className={`obituary-overlay fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center  backdrop-blur-md 
          transition-opacity duration-1000 ${fadeOut ? "opacity-0" : "opacity-100"}`}
          >
            <div className={`text-center text-white mx-2 md:mx-10 transition-opacity duration-1000 ${fadeIn ? "opacity-100" : "opacity-0"}`}>
              <img
                src="/assets/images/RatanTata3.png" 
                alt="In Loving Memory"
                className="mx-auto w-full h-auto max-w-3xl md:max-w-5xl" 
                style={{ maxHeight: '70vh', objectFit: 'contain' }}
              />
              
              <p className="mt-4 text-lg md:text-xl lg:text-2xl ">
                Ratan Tata
                <br /> 1937-2024
               < br /> <span className="text-sm md:text-base lg:text-lg italic"> 
                  A truly uncommon leader whose immeasurable contributions have shaped the very fabric of India.
                  <br /> His legacy will continue to inspire generations to come.
                </span>
              </p>
            </div>
          </div>
        )} */}

{showModal && (
   <div
   className={`fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center backdrop-blur-md transition-opacity duration-1000 ${
     fadeOut ? "opacity-0" : "opacity-100"
   }`}
   onClick={closeModal}
 >
    <div
     className={`text-center bg-gradient-to-br from-white via-blue-200 to-pink-200 text-gray-800 mx-4 md:mx-10 p-6 md:p-8 max-w-full md:max-w-[80%] lg:max-w-[70%] rounded-3xl shadow-2xl transition-opacity duration-1000  ${
       fadeIn ? "opacity-100" : "opacity-0"
     }`}
      onClick={(e) => e.stopPropagation()}
    >
      {/* Modal Content */}
      <h1 className="text-xl md:text-2xl lg:text-3xl font-extrabold mb-4 mt-2 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500 animate-pulse">
         ThinkQuotient is Launching a New Program Soon!
      </h1>
      <p className="mb-4 text-purple-600 text-base md:text-xl italic font-semibold">
        Transform Your Career with AI & Data Science!
      </p>

      <div className="flex flex-col md:flex-row justify-between items-center ">
        {/* Text Section */}
        <div className="w-full md:w-[60%] lg:w-[55%] text-left mb-4 md:mb-0 ml-10 md:ml-4 text-gray-700 text-sm md:text-base lg:text-lg ">
          <p className="my-2 flex items-center gap-2  font-semibold hover:text-pink-500 transition duration-300 transform hover:scale-105 hover:translate-x-2">
            <span className="text-pink-600 text-sm md:text-base lg:text-lg">✅</span> Python Programming
          </p>
          <p className="my-2 flex items-center gap-2  font-semibold hover:text-pink-500 transition duration-300 transform hover:scale-105 hover:translate-x-2">
            <span className="text-pink-600 text-sm md:text-base lg:text-lg">✅</span> Data Science & Machine Learning
          </p>
          <p className="my-2 flex items-center gap-2  font-semibold hover:text-pink-500 transition duration-300 transform hover:scale-105 hover:translate-x-2">
            <span className="text-pink-600 text-sm md:text-base lg:text-lg">✅</span> Generative AI (ChatGPT, DALLE, GANs)
          </p>
          <p className="my-2 flex items-center gap-2  font-semibold hover:text-pink-500 transition duration-300 transform hover:scale-105 hover:translate-x-2">
            <span className="text-pink-600 text-sm md:text-base lg:text-lg">✅</span> KNIME for Workflow Automation
          </p>
          <p className="my-2 flex items-center gap-2  font-semibold hover:text-pink-500 transition duration-300 transform hover:scale-105 hover:translate-x-2">
            <span className="text-pink-600 text-sm md:text-base lg:text-lg">✅</span> Hands-on Capstone Project
          </p>
        </div>

        {/* Image Section */}
        <div className="w-full md:w-[40%] lg:w-[35%] mb-2 lg:mb-8 flex justify-center">
          <img
            src="./assets/images/young-beautiful-girl-gray-hoody-holding-loudspeaker-looking-camera-with-surprise-standing-orange-background.png"
            alt="Announcement"
            className="w-[50%] h-auto md:w-[100%] lg:w-[90%]"
          />
        </div>
      </div> 

      <p className="mb-4  text-purple-800 text-sm md:text-base">
        💡 Gain in-demand skills, earn industry certifications, and advance your career!
      </p>
      <p className="mb-4 font-semibold text-red-600 text-sm md:text-base animate-bounce">
        Limited seats available – Enroll now!
      </p>
      <p className="mb-4">
       
        <p
          className="text-blue-600 hover:text-blue-800 hover:underline text-base md:text-2xl font-bold transition duration-300 ease-in-out"
        >
          <Link to="/contact" >Enquire now →</Link>
        </p>
      </p>
      <p className="font-bold text-purple-700 text-sm md:text-base tracking-wider">
        Let’s shape your future in AI! 🎯
      </p>
    </div>
  </div>
)}




        <Header />
        {/* Marquee */}
        <div class="marquee-wrapper">
          <div class="fixed-announcement"> Announcements </div>

          <div class="marquee-container">
            <div class="scrolling-text-wrapper">

              <div class="scrolling-text">
                <b>⭐ Free KNIME Webinar for Grads! (Dec 14th, 12 PM).</b> Launch your Data Analyst career with KNIME. Learn data skills & earn certifications. <span><b><a href="https://docs.google.com/forms/d/1S40jfWl-ukQOlCom8GvI7T4wzTaaYzzOKiszjFyUyHA/edit#responses" target="_blank">Register Now</a></b></span> &nbsp;&nbsp;&nbsp;
              </div>
              <div class="scrolling-text">
              <b>⭐ ThinkQuotient has successfully conducted the Coding Competition on Dec 5th. </b> 
               To Check results <Link to="/insights/tournaments" ><b>Click here</b></Link>
              </div>
              <div class="scrolling-text">
                <b>⭐ ThinkQuotient is coming to Nagpur! Join us on Dec 21st &amp; 22nd </b> for industry-relevant training, career guidance, and placement opportunities. <span><b><a href="https://forms.gle/GGbtJy89rZVxU9UN9" target="_blank">Register Now</a></b></span> &nbsp;&nbsp;&nbsp;
              </div>
              <div class="scrolling-text">
                <b>⭐ Transform Your Career with AI & Data Science! </b>
                  Join our Comprehensive Course covering: ✅ Python  ✅ Data Science & Machine Learning ✅ Generative AI (ChatGPT, DALLE, GANs) ✅ KNIME ✅ Hands-on Capstone Project
                <b> Starting Soon </b> – Limited seats available – Enroll now! &nbsp;&nbsp;&nbsp;
              </div>
            </div>
           
            <div class="scrolling-text-wrapper">
              <div class="scrolling-text">
                <b>⭐ Free KNIME Webinar for Grads! (Dec 14th, 12 PM).</b> Launch your Data Analyst career with KNIME. Learn data skills & earn certifications. <span><b><a href="https://docs.google.com/forms/d/1S40jfWl-ukQOlCom8GvI7T4wzTaaYzzOKiszjFyUyHA/edit#responses" target="_blank">Register Now</a></b></span> &nbsp;&nbsp;&nbsp;
              </div>
              <div class="scrolling-text">
              <b>⭐ ThinkQuotient has successfully conducted the Coding Competition on Dec 5th. </b> 
               To Check results <Link to="/insights/tournaments" ><b>Click here</b></Link>
              </div>
              <div class="scrolling-text">
                <b>⭐ ThinkQuotient is coming to Nagpur! Join us on Dec 21st &amp; 22nd</b> for industry-relevant training, career guidance, and placement opportunities. <span><b><a href="https://forms.gle/GGbtJy89rZVxU9UN9" target="_blank">Register Now</a></b></span> &nbsp;&nbsp;&nbsp;
              </div>
              <div class="scrolling-text">
                <b>⭐ Transform Your Career with AI & Data Science! </b>
                  Join our Comprehensive Course covering: ✅ Python  ✅ Data Science & Machine Learning ✅ Generative AI (ChatGPT, DALLE, GANs) ✅ KNIME ✅ Hands-on Capstone Project
                <b> Starting Soon</b> – Limited seats available – Enroll now! &nbsp;&nbsp;&nbsp;
              </div>
            </div>
           

          </div>

        </div>

        {/* Call-to-Action Section */}
        <div className="container mx-auto text-center pt-6 md:pt-6 pb-6 hero-sec fade-in-down">
          <h2 className="md:text-3xl text-xl font-bold md:w-2/3 mx-auto">
            ThinkQuotient specializes in IT development and   <br /> as well as in freshers training.          </h2>
          {/* <Link to="/contact">
            <button className="btn-style-1  px-10 py-3 ">Learn More</button>
          </Link> */}
        </div>

        <BannerSection />
        <GridSection />
        <TestimonialsandBlogSec />
        <BlogSec />
        <ClientLogoCarousel />
        <Footer />
      </div></>
  );
};

export default Home;
