import React, { useEffect } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";
import WhyUs from "../../Components/Home/WhyUs";
import { useTable, useSortBy } from "react-table";
import Pattern from "../../Components/Home/Pattern";
import NewPattern from "../../Components/Home/NewPattern";

const Tournaments = () => {
  useEffect(() => {
    document.title = "Tournaments - Think Quotient";
    AOS.init();

  }, []);
  
  const data = React.useMemo(
    () => [
      {
        participants: 65,
        mcq: 40,
        eligible: 34,
        winners: "To be announced",
      },
    ],
    []
  );

  // Define the columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Participants",
        accessor: "participants",
      },
      {
        Header: "MCQ Appeared",
        accessor: "mcq",
      },
      {
        Header: "Eligible for Coding Round",
        accessor: "eligible",
      },
      {
        Header: "Winners",
        accessor: "winners",
      },
    ],
    []
  );

  const totalParticipant = React.useMemo(() => [    
      "Kamna Chaturvedi", "Saurabh Jagtap", "Sayali Divekar", "Mrunali Wahane", "Vishal Asati", 
      "Rohan Bankar", "Aniket Nachan", "Sanchit More", "Mayuri Garud", "Sushant Sutar", 
      "Snehal Gorde", "Rajveer Bari", "Sadashiv Sambarkar", "Sakshi Pawar", "Priti Ladke", 
      "Shubham Sapkal", "Vaishnavi Shirude", "Shital Koli", "Ankita Mohite", "Jayashree Arude", 
      "Ashwini Banait", "Sakshi Gujar", "Vaishnavi Bhadange", "Sandeep Gaikwad", "Jyoti Gupta", 
      "Pratibha Verma", "Priyanka Magar", "Aishwarya Sahare", "Ajit Raut", "Jessie Houston", 
      "Abhshek Bagad", "Aditya Wankhade", "Nilesh Bhojane", "Pooja Pangarkar", "Rishikesh Dharme", 
      "Atharv Pankar", "Snehal Mali", "Chaitali Arale", "Akshata Kore", "Divya Sutar", 
      "Rutuja Patil", "Pratik Shinde", "Harshal Redkar", "Nikita Dudhade", "Apeksha Salunkhe", 
      "Vaishnavi Chaudhary", "Gayatri Joshi", "Shivam Gore", "Kushal Amrutkar", "Aishwarya Pore", 
      "Malti Dhadave", "Megha Awasthi", "Harshad Yadav", "Ashwini Kanade", "Pramod Waghchavare", 
      "Dhanraj Pawar", "Leonard Roth", "Payal Kuchik", "Ajinkya Borate", "Sharad Pawar", 
      "Snehal Kharche", "Rohini Jadhav", "Nidheya Labhade", "Diganta Shinde", "Akshay Uikey"
  ], []);

  const mcqAppeared = React.useMemo(() => [
    "Kamna Chaturvedi", "Saurabh Jagtap", "Sayali Divekar", "Rohan Bankar", "Sanchit More", 
    "Mayuri Garud", "Sushant Sutar", "Sadashiv Sambarkar", "Sakshi Pawar", "Shubham Sapkal", 
    "Vaishnavi Shirude", "Ashwini Banait", "Sakshi Gujar", "Vaishnavi Bhadange", "Jyoti Gupta", 
    "Pratibha Verma", "Aishwarya sahare", "Ajit Raut", "Abhshek Bagad", "Aditya Wankhade", 
    "Nilesh Bhojane", "Snehal Mali", "Akshata Kore", "Divya Sutar", "Pratik Shinde", "Harshal Redkar", 
    "Nikita Dudhade", "Apeksha Salunkhe", "Vaishnavi Chaudhary", "Gayatri Joshi", "Shivam Gore", 
    "Kushal Amrutkar", "Malti Dhadave", "Megha Awasthi", "Harshad Yadav", "Ajinkya Borate", 
    "Sharad Pawar", "Nidheya Labhade", "Diganta Shinde", "Akshay Uikey"
  ], []);

  const eligibleForCodingRound = React.useMemo(() => [
    "Kamna Chaturvedi", "Saurabh Jagtap", "Sayali Divekar", "Rohan Bankar", "Sanchit More", 
    "Mayuri Garud", "Sushant Sutar", "Sakshi Pawar", "Shubham Sapkal", "Vaishnavi Shirude", 
    "Ashwini Banait", "Sakshi Gujar", "Vaishnavi Bhadange", "Pratibha Verma", "Ajit Raut", 
    "Abhshek Bagad", "Aditya Wankhade", "Snehal Mali", "Akshata Kore", "Divya Sutar", "Pratik Shinde", 
    "Harshal Redkar", "Nikita Dudhade", "Apeksha Salunkhe", "Vaishnavi Chaudhary", "Gayatri Joshi", 
    "Shivam Gore", "Kushal Amrutkar", "Malti Dhadave", "Ajinkya Borate", "Sharad Pawar", 
    "Nidheya Labhade", "Diganta Shinde", "Akshay Uikey"
  ], []);

  const winners = React.useMemo(() => [
    "To be announced"
  ], []);
 
  // Define columns for the new table
  const participantsColumns = React.useMemo(
    () => [
      {
        Header: (
          <>
            Total Participants <br /> (65)
          </>
        ),
        accessor: "totalParticipant",
        disableSortBy: true,
      },
      {
        Header: (
          <>
            Appeared for MCQ Round <br /> (40)
          </>
        ),
        accessor: "mcqAppeared",
        disableSortBy: true,
      },
      {
        Header: (
          <>
            Eligible for Coding Round <br /> (34)
          </>
        ),
        accessor: "eligibleForCodingRound",
        disableSortBy: true,
      },
      {
        Header: (
          <>
            Winners <br /> (2)
          </>
        ),
        accessor: "winners",
        disableSortBy: true,
      },
    ],
    []
  );

  // Data for the new table (both participants and eligible participants)
  // const data2 = React.useMemo(
  //   () =>
  //     totalParticipant.map((participant, index) => ({
  //       totalParticipant: participant,
  //       mcqAppeared: mcqAppeared[index] || " ",
  //       eligibleForCodingRound: eligibleForCodingRound[index] || " ",
  //     })),
  //   [totalParticipant, mcqAppeared, eligibleForCodingRound]
  // );

  const data2 = React.useMemo(() => {
    // Find the longest array length
    const maxLength = Math.max(
      totalParticipant.length,
      mcqAppeared.length,
      eligibleForCodingRound.length,
      winners.length
    );
  
    // Map through the indices of the longest array
    return Array.from({ length: maxLength }, (_, index) => ({
      totalParticipant: totalParticipant[index] || " ", // Default for missing values
      mcqAppeared: mcqAppeared[index] || " ",          // Default for missing values
      eligibleForCodingRound: eligibleForCodingRound[index] || " ",
      winners:winners[index] || " " // Default for missing values
    }));
  }, [totalParticipant, mcqAppeared, eligibleForCodingRound, winners]);
  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: participantsColumns, data: data2 }, useSortBy);

  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
     <Helmet>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Script for Facebook */}
        {helmetContent.script}

        {/* Updated Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
          `}
        </script>

        {/* Google Analytics Scripts */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
          `}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
          `}
        </script>

        {/* Google Tag Manager noscript fallback */}
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      </Helmet>
      
      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />
        <NewPattern />
        {/* Banner Section */}
        <div>
       
            <div className="tournament-banner-sec flex  justify-center h-screen">
                <div className="container text-center text-white mx-auto">
                <div
                    className="mx-4 md:mx-8 relative top-[20%] md:top-[20%] lg:top-[20%] left-0 right-0 text-left text-white"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >  
                <h1 className="text-xl md:text-3xl lg:text-4xl font-semibold mb-2">
                    ThinkQuotient Coding Challenge
                </h1>
                <h2 className="text-lg md:text-3xl font-medium">
                    Dec 2024
                </h2>
                </div>
                </div>
            </div>
        </div>

          {/* Webinar Information Section */}
        <div className="bg-white py-8 pt-12 mt-8 mb-8"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
            <div className="container mx-auto text-center item-center">
           <h2 className="text-2xl md:text-5xl font-bold text-black mb-6 tracking-wide leading-tight">
              Code, Compete, Conquer !!
            </h2>
            <p className="text-lg md:text-2xl text-black mb-6 ">
              ThinkQuotient Coding Challenge, December 2024
            </p>
           
           

             {/* Table Section */}
       
        
        <div className="p-4 overflow-x-auto mb-4">
          <table className="min-w-full table-auto bg-white rounded-lg shadow-lg border border-blue-600">
                <thead className="" style={{ backgroundColor: 'rgb(33, 68, 145)' }}>
                <tr>
                    {columns.map((column) => (
                    <th
                        key={column.accessor}
                        className="py-3 px-4 text-center text-sm md:text-lg  font-semibold text-white border-b border-blue-300"
                    >
                        {column.Header}
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((row, rowIndex) => (
                    <tr
                    key={rowIndex}
                    className="border-b border-blue-200 hover:bg-blue-50"
                    >
                    {columns.map((column) => (
                        <td
                        key={column.accessor}
                        className="py-3 px-4 text-sm text-blue-800"
                        >
                        {row[column.accessor]}
                        </td>
                    ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

         {/* New Table with Participants and Eligible Participants */}
         <div className="p-4 overflow-x-auto mb-4">
          <table
            {...getTableProps()}
            className="min-w-full table-auto bg-white rounded-lg shadow-lg border border-blue-600"
          >
            <thead style={{ backgroundColor: 'rgb(33, 68, 145)' }}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="py-3 px-4 text-center text-sm md:text-lg font-semibold text-white border-b border-blue-300"
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="border-b border-blue-200 hover:bg-blue-50">
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()} className="py-3 px-4 text-sm text-blue-800 text-left sm:pl-2 md:pl-6 lg:pl-20">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>

            </div>
        </div>

       
  

        {/* text and image box */}
        <WhyUs />
        <Footer />
      </div>
    </>
  );
};

export default Tournaments;